// @flow
/* eslint-disable */
// Generated from svgs/Flag_of_Italy.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FlagOfItaly = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 3 2" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path fill="#009246" d="M0 0h3v2H0z" /><path fill="#fff" d="M1 0h2v2H1z" /><path fill="#ce2b37" d="M2 0h1v2H2z" /></svg>
  );
}
