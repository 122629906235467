// @flow
/* eslint-disable */
// Generated from svgs/Flag_of_the_United_Kingdom.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FlagOfTheUnitedKingdom = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 60 40" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><clipPath id="Flag_of_the_United_Kingdom_svg__a"><path d="M30 15h30v15zm0 0v15H0zm0 0H0V0zm0 0V0h30z" /></clipPath></defs><path style={{"fill":"#00247d"}} d="M0 0h60v40H0z" /><g style={{"stroke":"#fff","strokeWidth":"6.93225527","fill":"none"}}><path d="m0 0 60 40m0-40L0 40" /><path d="m0 0 60 30m0-30L0 30" style={{"stroke":"#cf142b","strokeWidth":"4"}} clipPath="url(#Flag_of_the_United_Kingdom_svg__a)" transform="scale(1 1.33333)" /><path d="M30 0v40M0 20h60" style={{"strokeWidth":"11.55375878"}} /><path d="M30 0v40M0 20h60" style={{"stroke":"#cf142b"}} /></g></svg>
  );
}
