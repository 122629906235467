// @flow
/* eslint-disable */
// Generated from svgs/agriculture.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Agriculture = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M7 3v2.145c-.362.094-.702.24-1.016.425l-1.52-1.52-1.413 1.415 1.52 1.52A3.978 3.978 0 0 0 4.144 8H2v2h2.14c.096.366.242.71.426 1.03a32.613 32.613 0 0 0-2.095 1.122L2 12.445V19c0 1.093.907 2 2 2h16c1.093 0 2-.907 2-2V7h-1c-3.313 0-6.402.532-9.076 1.252a3.925 3.925 0 0 0-.49-1.272l1.515-1.515-1.414-1.414-1.525 1.525A4.008 4.008 0 0 0 9 5.145V3H7Zm1 4c1.058 0 1.897.797 1.982 1.83a40.03 40.03 0 0 0-3.59 1.363A1.978 1.978 0 0 1 6 9c0-1.117.883-2 2-2Zm12 2.111v1.076c-8.712 1.78-14.205 5.94-16 7.481v-4.043c.647-.39 7.266-4.2 16-4.514Zm0 3.12v1.167c-4.929 2.545-7.676 4.941-8.387 5.602H5.508C7.29 17.5 12.206 13.907 20 12.23Zm0 3.431V19h-5.248c1.202-.911 2.943-2.086 5.248-3.338Z" fill="#000" /></svg>
  );
}
