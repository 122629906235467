// @flow
/* eslint-disable */
// Generated from svgs/dropdown.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Dropdown = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path id="dropdown_svg__a" d="M5.5 6 4 7.5l6.07 6.07 6.07-6.07-1.5-1.5-4.57 4.57z" /></defs><use fill="#000" xlinkHref="#dropdown_svg__a" fillRule="evenodd" /></svg>
  );
}
