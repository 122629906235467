// @flow
/* eslint-disable */
// Generated from svgs/parking.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Parking = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 18 19" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M10 .906 2.187 4.38A2.005 2.005 0 0 0 1 6.205V19h2V6.205l7-3.111 7 3.111V19h2V6.205c0-.787-.469-1.506-1.188-1.826l-.001-.002L10 .907ZM8.318 8A2 2 0 0 0 6.48 9.213L5 12.668V18a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1h4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-5.332l-1.48-3.455A2 2 0 0 0 11.682 8H8.318Zm0 2h3.364l.857 2H7.461l.857-2ZM7 14h6v2H7v-2Z" id="parking_svg__a" /></defs><use fill="#000" fillRule="nonzero" xlinkHref="#parking_svg__a" transform="translate(-1)" /></svg>
  );
}
