// @flow
/* eslint-disable */
// Generated from svgs/user-couple.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const UserCouple = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path opacity=".2" d="M6.404 2a1.976 1.976 0 0 0-1 .278 1.982 1.982 0 0 1 0 3.444 1.976 1.976 0 0 0 1 .278 2 2 0 0 0 0-4zM6.404 9a3.954 3.954 0 0 0-1 .142 3.996 3.996 0 0 1 3 3.858v3.5a.5.5 0 0 1-.5.5h-.5a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h2a.5.5 0 0 0 .5-.5V18a1 1 0 0 1 1-1h.5a.5.5 0 0 0 .5-.5V13a4.005 4.005 0 0 0-4-4z" /><path d="M6.404 7a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm0-5a2 2 0 1 1-2 2 2.002 2.002 0 0 1 2-2zM6.404 8a5 5 0 0 0-5 5v3.5a1.5 1.5 0 0 0 1.5 1.5h.5v5.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V18h.5a1.5 1.5 0 0 0 1.5-1.5V13a5 5 0 0 0-5-5zm4 8.5a.5.5 0 0 1-.5.5h-.5a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V18a1 1 0 0 0-1-1h-.5a.5.5 0 0 1-.5-.5V13a4 4 0 0 1 8 0v3.5z" /><path opacity=".2" d="M18.629 2a1.976 1.976 0 0 0-1 .278 1.982 1.982 0 0 1 0 3.444 1.976 1.976 0 0 0 1 .278 2 2 0 0 0 0-4zM18.629 9a3.954 3.954 0 0 0-1 .142 3.996 3.996 0 0 1 3 3.858v3.5a.5.5 0 0 1-.5.5h-.5a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h2a.5.5 0 0 0 .5-.5V18a1 1 0 0 1 1-1h.5a.5.5 0 0 0 .5-.5V13a4.005 4.005 0 0 0-4-4z" /><path d="M18.629 7a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm0-5a2 2 0 1 1-2 2 2.002 2.002 0 0 1 2-2zM18.629 8a5 5 0 0 0-5 5v3.5a1.5 1.5 0 0 0 1.5 1.5h.5v5.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V18h.5a1.5 1.5 0 0 0 1.5-1.5V13a5 5 0 0 0-5-5zm4 8.5a.5.5 0 0 1-.5.5h-.5a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V18a1 1 0 0 0-1-1h-.5a.5.5 0 0 1-.5-.5V13a4 4 0 0 1 8 0v3.5z" /></svg>
  );
}
