// @flow
/* eslint-disable */
// Generated from svgs/apartment.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Apartment = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M9 2.748 2 8.031V19c0 1.093.907 2 2 2h16c1.093 0 2-.907 2-2V8.031l-6.965-5.258-3.185 2.125L9 2.748Zm5.965 2.479L20 9.027V19h-4v-2h2v-2h-2v-2h2v-2h-2V8.031l-2.459-1.855 1.424-.95ZM9 5.252l5 3.775V19H4V9.027l5-3.775ZM6 11v2h2v-2H6Zm4 0v2h2v-2h-2Zm-4 4v2h2v-2H6Zm4 0v2h2v-2h-2Z" fill="#000" /></svg>
  );
}
