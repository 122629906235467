// @flow
/* eslint-disable */
// Generated from svgs/money.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Money = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 9 15" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M4.567 6.583c-1.892-.491-2.5-1-2.5-1.791 0-.909.841-1.542 2.25-1.542C5.8 3.25 6.35 3.958 6.4 5h1.842c-.059-1.433-.934-2.75-2.675-3.175V0h-2.5v1.8C1.45 2.15.15 3.2.15 4.808c0 1.925 1.592 2.884 3.917 3.442 2.083.5 2.5 1.233 2.5 2.008 0 .575-.409 1.492-2.25 1.492-1.717 0-2.392-.767-2.484-1.75H0c.1 1.825 1.467 2.85 3.067 3.192V15h2.5v-1.792c1.625-.308 2.916-1.25 2.916-2.958 0-2.367-2.025-3.175-3.916-3.667Z" fill="#777" /></svg>
  );
}
