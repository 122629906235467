// @flow
/* eslint-disable */
// Generated from svgs/bed.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Bed = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M4 1a2 2 0 0 0-2 2v5.277A1.99 1.99 0 0 0 1 10v9h2v-2h14v2h2v-9a1.99 1.99 0 0 0-1-1.723V3a2 2 0 0 0-2-2H4Zm0 2h12v5h-2V6a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2H4V3Zm-1 7h14v5H3v-5Z" id="bed_svg__a" /></defs><g fill="none" fillRule="evenodd"><mask id="bed_svg__b" fill="#fff"><use xlinkHref="#bed_svg__a" /></mask><use fill="#000" fillRule="nonzero" xlinkHref="#bed_svg__a" /><g mask="url(#bed_svg__b)" fill="#2A2A2A"><path d="M0 0h20v20H0z" /></g></g></svg>
  );
}
