// @flow
/* eslint-disable */
// Generated from svgs/user-single.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const UserSingle = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M13 2a1.976 1.976 0 0 0-1 .278 1.982 1.982 0 0 1 0 3.444A1.976 1.976 0 0 0 13 6a2 2 0 0 0 0-4ZM13 9a3.954 3.954 0 0 0-1 .142A3.996 3.996 0 0 1 15 13v3.5a.5.5 0 0 1-.5.5H14a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h2a.5.5 0 0 0 .5-.5V18a1 1 0 0 1 1-1h.5a.5.5 0 0 0 .5-.5V13a4.005 4.005 0 0 0-4-4Z" opacity=".2" /><path d="M13 7a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm0-5a2 2 0 1 1-2 2 2.002 2.002 0 0 1 2-2ZM13 8a5 5 0 0 0-5 5v3.5A1.5 1.5 0 0 0 9.5 18h.5v5.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V18h.5a1.5 1.5 0 0 0 1.5-1.5V13a5 5 0 0 0-5-5Zm4 8.5a.5.5 0 0 1-.5.5H16a1 1 0 0 0-1 1v5.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V18a1 1 0 0 0-1-1h-.5a.5.5 0 0 1-.5-.5V13a4 4 0 0 1 8 0Z" /></svg>
  );
}
