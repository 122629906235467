// @flow
/* eslint-disable */
// Generated from svgs/land.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Land = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 48 48" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M34.56 15.36h1.92l5.76 21.12h-5.76l-1.92-19.2v-1.92ZM13.44 7.68h1.92l7.68 28.8h-5.76L13.44 9.6V7.68Z" fillOpacity=".2" /><path d="M47.04 46.08H36.48V38.4h7.68L35.88 9.851a.384.384 0 0 0-.72 0L26.88 38.4h7.68v7.68h-19.2V38.4h9.6L14.769 2.197a.384.384 0 0 0-.738 0L3.84 38.4h9.6v7.68H2.88a.96.96 0 1 0 0 1.92h44.16a.96.96 0 1 0 0-1.92ZM35.52 15.502l3.56 12.277-1.108-.738a.955.955 0 0 0-1.21.12l-2.202 2.2-2.201-2.2a.94.94 0 0 0-.185-.124l3.346-11.535ZM29.436 36.48l2.142-7.385 2.304 2.304a.96.96 0 0 0 1.357 0l2.324-2.323 2.225 1.483a.84.84 0 0 0 .113.046l1.703 5.875H29.436ZM14.4 7.972l4.995 17.744-1.436-1.436a.96.96 0 0 0-1.357 0l-3.162 3.162-3.161-3.162a.937.937 0 0 0-.406-.228L14.4 7.972ZM6.374 36.48l2.941-10.447 3.446 3.445a.96.96 0 0 0 1.358 0l3.161-3.162 3.161 3.162.016.01 1.969 6.992H6.374Z" /></svg>
  );
}
