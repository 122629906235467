// @flow
/* eslint-disable */
// Generated from svgs/check-mark.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const CheckMark = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M19.281 5.281 9 15.563 4.719 11.28 3.28 12.72l5 5 .719.687.719-.687 11-11L19.28 5.28Z" /></svg>
  );
}
