// @flow
/* eslint-disable */
// Generated from svgs/bedroom.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Bedroom = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M5 4a2 2 0 0 0-2 2v4.277A1.99 1.99 0 0 0 2 12v9h2v-2h16v2h2v-9a1.99 1.99 0 0 0-1-1.723V6a2 2 0 0 0-2-2H5zm0 2h14v4h-1v-.5a2.5 2.5 0 1 0-5 0v.5h-2v-.5a2.5 2.5 0 1 0-5 0v.5H5V6zm-1 6h16v5H4v-5z" /></svg>
  );
}
