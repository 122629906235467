// @flow
/* eslint-disable */
// Generated from svgs/floor-plan.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FloorPlan = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V5c0-1.093-.907-2-2-2h-4v2h4v6h-2v2h2v6h-7v-6h2v-2H8v2h2v6H5V5h4.586l2.99 2.99 1.414-1.414L10.414 3H5Z" fill="#000" /></svg>
  );
}
