// @flow
/* eslint-disable */
// Generated from svgs/news.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const News = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><g fill="#2A2A2A" fillRule="evenodd"><path d="M5 16h10a1.5 1.5 0 0 0 1.5-1.5V4.3a.3.3 0 0 0-.3-.3H5.8a.3.3 0 0 0-.3.3V14h-2v.5A1.5 1.5 0 0 0 5 16Zm-1.5-4V2.7a.7.7 0 0 1 .7-.7h13.6a.7.7 0 0 1 .7.7V15a3 3 0 0 1-3 3h-11a3 3 0 0 1-3-3v-2.3a.7.7 0 0 1 .7-.7h1.3Z" fillRule="nonzero" /><path d="M12.5 14h-9v-2h9.7a1.3 1.3 0 0 1 1.3 1.3V18h-2v-4Z" fillRule="nonzero" /><path d="M7.3 5.5h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H7.3a.3.3 0 0 1-.3-.3V5.8a.3.3 0 0 1 .3-.3Zm3 0h3.9a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3h-3.9a.3.3 0 0 1-.3-.3V5.8a.3.3 0 0 1 .3-.3Zm-3 3h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H7.3a.3.3 0 0 1-.3-.3V8.8a.3.3 0 0 1 .3-.3Zm3 0h3.9a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3h-3.9a.3.3 0 0 1-.3-.3V8.8a.3.3 0 0 1 .3-.3Z" /></g></svg>
  );
}
