// @flow
/* eslint-disable */
// Generated from svgs/Flag_of_Switzerland.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FlagOfSwitzerland = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 32 32" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M0 0h32v32H0z" fill="red" /><path d="M13 6h6v7h7v6h-7v7h-6v-7H6v-6h7z" fill="#fff" /></svg>
  );
}
