// @flow
/* eslint-disable */
// Generated from svgs/progress_arrow.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ProgressArrow = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 8 198" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M3.646 196.887a.499.499 0 0 0 .708 0l3.182-3.182a.5.5 0 1 0-.708-.707L4 195.827l-2.828-2.829a.5.5 0 0 0-.708.707l3.182 3.182ZM3.5.143v2.505h1V.143h-1Zm0 4.51v5.01h1v-5.01h-1Zm0 7.013v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.009h1v-5.009h-1Zm0 7.013v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v5.01h1v-5.01h-1Zm0 7.014v2.505h1v-2.505h-1Z" /></svg>
  );
}
