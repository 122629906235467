// @flow
/* eslint-disable */
// Generated from svgs/price.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Price = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M4 1C2.346 1 1 2.346 1 4v13c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2H4a1.001 1.001 0 0 1 0-2h14V1H4ZM3 6.816C3.314 6.93 3.648 7 4 7h13v10H3V6.816ZM14 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" id="price_svg__a" /></defs><g fill="none" fillRule="evenodd"><mask id="price_svg__b" fill="#fff"><use xlinkHref="#price_svg__a" /></mask><use fill="#000" fillRule="nonzero" xlinkHref="#price_svg__a" /><g mask="url(#price_svg__b)" fill="#2A2A2A"><path d="M0 0h20v20H0z" /></g></g></svg>
  );
}
