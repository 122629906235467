// @flow
/* eslint-disable */
// Generated from svgs/area.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Area = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M1 3v4h2V3h4V1H3c-1.1 0-2 .9-2 2Zm2 10H1v4c0 1.1.9 2 2 2h4v-2H3v-4Zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4Zm0-16h-4v2h4v4h2V3c0-1.1-.9-2-2-2Z" id="area_svg__a" /></defs><use fill="#05004D" fillRule="nonzero" xlinkHref="#area_svg__a" transform="translate(-1 -1)" /></svg>
  );
}
