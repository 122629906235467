// @flow
/* eslint-disable */
// Generated from svgs/facebook.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Facebook = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 11 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M6.742 20v-9.123h3.062l.458-3.555h-3.52v-2.27c0-1.03.285-1.731 1.762-1.731l1.882-.001V.14A25.231 25.231 0 0 0 7.643 0C4.929 0 3.07 1.657 3.07 4.7v2.622H0v3.555h3.07V20h3.672Z" /></svg>
  );
}
