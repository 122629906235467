// @flow
/* eslint-disable */
// Generated from svgs/twitter.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Twitter = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 17" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><g clipPath="url(#twitter_svg__a)"><path d="M6.315 16.212c7.497 0 11.597-6.21 11.597-11.597 0-.176-.004-.352-.011-.527a8.29 8.29 0 0 0 2.033-2.11 8.127 8.127 0 0 1-2.341.641A4.09 4.09 0 0 0 19.385.365a8.17 8.17 0 0 1-2.588.99 4.077 4.077 0 0 0-6.946 3.717A11.573 11.573 0 0 1 1.45.811a4.064 4.064 0 0 0-.552 2.05c0 1.413.72 2.662 1.814 3.392a4.046 4.046 0 0 1-1.846-.51v.052a4.077 4.077 0 0 0 3.27 3.996 4.083 4.083 0 0 1-1.841.07 4.08 4.08 0 0 0 3.807 2.83 8.176 8.176 0 0 1-5.062 1.745c-.329 0-.653-.018-.972-.056a11.538 11.538 0 0 0 6.248 1.831" fill="#1DA1F2" /></g><defs><clipPath id="twitter_svg__a"><path fill="#fff" d="M0 0h20v16.28H0z" /></clipPath></defs></svg>
  );
}
