// @flow
/* eslint-disable */
// Generated from svgs/warning.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Warning = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 122.89 111.55" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="m9 88.76 43.15-74.6c5.23-8.25 13.53-8.46 18.87 0l42.44 73.7c3.38 6.81 1.7 16-9.34 15.77h-86.5c-7.27.18-12-6.19-8.64-14.87Z" style={{"fillRule":"evenodd"}} /><path d="M57.57 82.7a5.51 5.51 0 0 1 3.48-1.58 5.75 5.75 0 0 1 2.4.35 5.82 5.82 0 0 1 2 1.31 5.53 5.53 0 0 1 1.62 3.55 6.05 6.05 0 0 1-.08 1.4 5.54 5.54 0 0 1-5.64 4.6 5.67 5.67 0 0 1-2.27-.52 5.56 5.56 0 0 1-2.82-2.94 5.65 5.65 0 0 1-.35-1.27 5.83 5.83 0 0 1-.06-1.31 6.19 6.19 0 0 1 .57-2 4.57 4.57 0 0 1 1.13-1.56Zm8.16-10.24c-.2 4.79-8.31 4.8-8.5 0-.82-8.21-2.92-29.39-2.85-37.1.07-2.38 2-3.79 4.56-4.33a12.83 12.83 0 0 1 5 0c2.61.56 4.65 2 4.65 4.44v.24l-2.86 36.75Z" style={{"fill":"#fff"}} /></svg>
  );
}
