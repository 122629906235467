// @flow
/* eslint-disable */
// Generated from svgs/commerce.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Commerce = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M6 1v2.434l-5 3V17c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V6h-2V5h-3V1H6Zm2 2h4v14H8V3ZM6 5.766V17H3V7.566l3-1.8ZM14 7h1v1h2v9h-3V7Z" id="commerce_svg__a" /></defs><use fill="#000" fillRule="nonzero" xlinkHref="#commerce_svg__a" transform="translate(-1 -1)" /></svg>
  );
}
