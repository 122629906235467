// @flow
/* eslint-disable */
// Generated from svgs/logo.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Logo = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 40 40" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><linearGradient x1="15.093%" y1="84.907%" x2="85.571%" y2="14.429%" id="logo_svg__a"><stop stopColor="#41B0FF" offset="0%" /><stop stopColor="#30A9FF" stopOpacity="0" offset="100%" /></linearGradient></defs><g fill="none" fillRule="evenodd"><path d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20A20 20 0 0 0 20 0Zm5.686 23.766a2.834 2.834 0 0 1-1.92 1.92l-13.392 3.94 3.937-13.392a2.834 2.834 0 0 1 1.92-1.92l13.395-3.94-3.94 13.392Z" fill="#183399" /><path d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20A20 20 0 0 0 20 0Zm5.686 23.766a2.834 2.834 0 0 1-1.92 1.92l-13.392 3.94 3.937-13.392a2.834 2.834 0 0 1 1.92-1.92l13.395-3.94-3.94 13.392Z" fill="url(#logo_svg__a)" /></g></svg>
  );
}
