// @flow
/* eslint-disable */
// Generated from svgs/arrow_right_long.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ArrowRightLong = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" style={{"enableBackground":"new 0 0 24 24"}} xmlSpace="preserve" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M24 24H0V0h24v24z" style={{"fill":"none"}} /><path d="M3 13h14.2l-3.6 3.6L15 18l6-6-6-6-1.4 1.4 3.6 3.6H3v2z" /></svg>
  );
}
