// @flow
/* eslint-disable */
// Generated from svgs/cloud-server.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const CloudServer = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 64 74" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M45.633 12.994a6.084 6.084 0 0 1-6.014 5.292H22.857a10.623 10.623 0 0 1-7.467-3.069 10.65 10.65 0 0 0 10.515 9.164h16.762a6.075 6.075 0 0 0 2.966-11.387ZM54.857 36.571h6.095v33.524h-6.095z" opacity=".2" /><path d="M53.333 60.952H25.905a1.524 1.524 0 1 0 0 3.048h27.428a1.524 1.524 0 1 0 0-3.048ZM13.714 57.905a4.571 4.571 0 1 0 0 9.143 4.571 4.571 0 0 0 0-9.143Zm0 6.095a1.524 1.524 0 1 1 0-3.048 1.524 1.524 0 0 1 0 3.048ZM25.905 45.714h27.428a1.524 1.524 0 1 0 0-3.047H25.905a1.524 1.524 0 1 0 0 3.047Z" /><path d="M59.429 33.524H4.57A4.571 4.571 0 0 0 0 38.095v30.476a4.571 4.571 0 0 0 4.571 4.572H59.43A4.571 4.571 0 0 0 64 68.57V38.095a4.571 4.571 0 0 0-4.571-4.571ZM4.57 36.57H59.43c.841 0 1.523.683 1.523 1.524V51.81H3.048V38.095c0-.841.682-1.524 1.523-1.524ZM59.43 70.095H4.57a1.524 1.524 0 0 1-1.523-1.524V54.857h57.904v13.714c0 .842-.682 1.524-1.523 1.524Z" /><path d="M13.714 48.762a4.571 4.571 0 1 0 0-9.143 4.571 4.571 0 0 0 0 9.143Zm0-6.095a1.524 1.524 0 1 1 0 3.047 1.524 1.524 0 0 1 0-3.047ZM25.905 27.429h16.762a9.143 9.143 0 1 0-3.65-17.518 13.687 13.687 0 1 0-13.112 17.518Zm0-24.381A10.557 10.557 0 0 1 36.09 10.76a3.048 3.048 0 0 0 4.145 1.943 6.092 6.092 0 1 1 2.43 11.677H25.906c-5.891 0-10.667-4.776-10.667-10.667 0-5.89 4.776-10.666 10.667-10.666Z" /></svg>
  );
}
