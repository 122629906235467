// @flow
/* eslint-disable */
// Generated from svgs/keyboard_arrow_right.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const KeyboardArrowRight = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" /><path fill="none" d="M0 0h24v24H0V0z" /></svg>
  );
}
