// @flow
/* eslint-disable */
// Generated from svgs/stock.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Stock = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 64 62" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M61.44 47.36a1.28 1.28 0 0 1-1.28 1.28H3.84a1.28 1.28 0 0 1-1.28-1.28v-6.4h58.88v6.4Z" opacity=".2" /><path d="M60.16 0H3.84A3.845 3.845 0 0 0 0 3.84v43.52a3.845 3.845 0 0 0 3.84 3.84h21.248l-1.536 7.68H19.2a1.28 1.28 0 0 0 0 2.56h25.6a1.28 1.28 0 0 0 0-2.56h-4.352l-1.536-7.68H60.16A3.845 3.845 0 0 0 64 47.36V3.84A3.845 3.845 0 0 0 60.16 0ZM37.837 58.88H26.163L27.7 51.2h8.602l1.536 7.68ZM61.44 47.36a1.28 1.28 0 0 1-1.28 1.28H3.84a1.28 1.28 0 0 1-1.28-1.28v-6.4h58.88v6.4Zm0-8.96H2.56V3.84c0-.707.573-1.28 1.28-1.28h56.32c.707 0 1.28.573 1.28 1.28V38.4Z" /><path d="M11.52 23.04a1.28 1.28 0 0 0-1.28 1.28V32a1.28 1.28 0 0 0 2.56 0v-7.68a1.28 1.28 0 0 0-1.28-1.28zm10.24-12.8a1.28 1.28 0 0 0-1.28 1.28V32a1.28 1.28 0 0 0 2.56 0V11.52a1.28 1.28 0 0 0-1.28-1.28zM32 25.6a1.28 1.28 0 0 0-1.28 1.28V32a1.28 1.28 0 0 0 2.56 0v-5.12A1.28 1.28 0 0 0 32 25.6zm10.24-10.24a1.28 1.28 0 0 0-1.28 1.28V32a1.28 1.28 0 0 0 2.56 0V16.64a1.28 1.28 0 0 0-1.28-1.28zm10.24 5.12a1.28 1.28 0 0 0-1.28 1.28V32a1.28 1.28 0 0 0 2.56 0V21.76a1.28 1.28 0 0 0-1.28-1.28z" /></svg>
  );
}
