// @flow
/* eslint-disable */
// Generated from svgs/check.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Check = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 15" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M7.059 10.384 2.353 5.77 0 8.077l4.706 4.615L7.059 15l2.353-2.308L20 2.308 17.647 0z" /></svg>
  );
}
