// @flow
/* eslint-disable */
// Generated from svgs/house.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const House = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M8.03 3c-.647 0-1.253.312-1.628.838L2.565 9.209a.5.5 0 0 0 .408.791H3v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V10h.027a.5.5 0 0 0 .409-.791l-3.838-5.371A2 2 0 0 0 15.97 3H8.029Zm.47 2.1 2.902 4.062c.375.526.981.838 1.627.838H19v9h-8v-5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v5H5v-9l3.5-4.9ZM14 13a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2Z" /></svg>
  );
}
