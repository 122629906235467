// @flow
/* eslint-disable */
// Generated from svgs/star.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Star = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 10 9" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="m4.878.18 1.407 2.85 3.145.459-2.276 2.216.537 3.132-2.813-1.48-2.812 1.48.537-3.132L.327 3.49l3.145-.46L4.878.18Z" fill="#FFDD75" /></svg>
  );
}
