// @flow
/* eslint-disable */
// Generated from svgs/location_pin.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const LocationPin = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><defs><path d="M9.992 0C6.136 0 3 3.138 3 6.994c0 4.483 5.967 11.765 6.22 12.072l.772.936.772-.936c.254-.308 6.22-7.589 6.22-12.072C16.984 3.137 13.848 0 9.992 0Zm0 2a5 5 0 0 1 4.992 4.994c0 2.705-3.114 7.357-4.992 9.822C8.114 14.353 5 9.703 5 6.994A5 5 0 0 1 9.992 2Zm0 2.49a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" id="location_pin_svg__a" /></defs><g fill="none" fillRule="evenodd"><mask id="location_pin_svg__b" fill="#fff"><use xlinkHref="#location_pin_svg__a" /></mask><use fill="#000" fillRule="nonzero" xlinkHref="#location_pin_svg__a" /><g mask="url(#location_pin_svg__b)" fill="#2A2A2A"><path d="M0 0h20v20H0z" /></g></g></svg>
  );
}
