// @flow
/* eslint-disable */
// Generated from svgs/rocket.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Rocket = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 52 68" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M25.84 3.952a90.466 90.466 0 0 0-1.594 1.805c4.887 6.14 11.114 15.63 11.114 24.163 0 15.973-8.688 24.48-14.96 24.48a7.525 7.525 0 0 1-1.232-.107 10.773 10.773 0 0 0 6.672 2.827c6.272 0 14.96-8.507 14.96-24.48 0-11.1-10.546-23.842-14.96-28.688Z" opacity=".2" /><path d="M25.84 27.2a6.8 6.8 0 1 0 0 13.6 6.8 6.8 0 0 0 0-13.6Zm0 10.88a4.08 4.08 0 1 1 0-8.16 4.08 4.08 0 0 1 0 8.16Z" /><circle cx="25.84" cy="17.68" r="1.36" /><path d="M43.326 29.816C41.42 15.285 25.84 0 25.84 0S10.26 15.285 8.354 29.816C5.086 33.279 0 38.976 0 40.8 0 44.2 3.513 68 5.44 68c1.615-1.615 1.807-13.281 8.24-15.81a22.452 22.452 0 0 0 2.64 3.12v7.25a2.72 2.72 0 0 0 2.72 2.72h2.97a4.057 4.057 0 0 0 7.66 0h2.97a2.72 2.72 0 0 0 2.72-2.72v-7.25A22.452 22.452 0 0 0 38 52.19c6.433 2.529 6.625 14.195 8.24 15.81 1.927 0 5.44-23.8 5.44-27.2 0-1.824-5.086-7.52-8.354-10.984ZM5.373 58.94a175.153 175.153 0 0 1-2.65-17.932 39.128 39.128 0 0 1 5.485-7.034 34.623 34.623 0 0 0 4.003 15.869 14.373 14.373 0 0 0-6.838 9.097ZM19.04 62.56v-5.033a15.163 15.163 0 0 0 2.72 1.452v3.581h-2.72Zm6.8 2.72c-.75 0-1.36-.61-1.36-1.36v-4.184a8.95 8.95 0 0 0 2.72 0v4.184c0 .75-.61 1.36-1.36 1.36Zm6.8-2.72h-2.72v-3.581c.954-.39 1.865-.877 2.72-1.453v5.034Zm-6.8-5.44c-6.272 0-14.96-8.507-14.96-24.48 0-11.1 10.546-23.842 14.96-28.688C30.254 8.798 40.8 21.54 40.8 32.64c0 15.973-8.688 24.48-14.96 24.48Zm20.467 1.82a14.373 14.373 0 0 0-6.838-9.096 34.623 34.623 0 0 0 4.003-15.87 39.136 39.136 0 0 1 5.484 7.035 175.153 175.153 0 0 1-2.649 17.932Z" /></svg>
  );
}
