// @flow
/* eslint-disable */
// Generated from svgs/verified-badge.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const VerifiedBadge = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><g clipPath="url(#verified-badge_svg__a)"><path d="M24 12.563c0 .839-.202 1.617-.605 2.33-.403.712-.942 1.27-1.622 1.659.02.126.029.323.029.59 0 1.27-.427 2.349-1.27 3.24-.85.895-1.871 1.34-3.066 1.34a4.015 4.015 0 0 1-1.529-.295 4.649 4.649 0 0 1-1.621 1.86A4.03 4.03 0 0 1 12 24c-.858 0-1.636-.23-2.33-.698a4.544 4.544 0 0 1-1.607-1.875c-.483.196-.99.295-1.529.295-1.195 0-2.221-.445-3.08-1.34-.857-.891-1.284-1.974-1.284-3.24 0-.14.02-.337.052-.59A4.346 4.346 0 0 1 .6 14.892a4.7 4.7 0 0 1-.6-2.33c0-.89.225-1.71.67-2.451a4.132 4.132 0 0 1 1.796-1.645 4.635 4.635 0 0 1-.296-1.608c0-1.266.427-2.349 1.285-3.24.857-.89 1.884-1.34 3.08-1.34.534 0 1.045.099 1.527.295A4.649 4.649 0 0 1 9.684.713 4.065 4.065 0 0 1 12 0c.84 0 1.613.24 2.316.708a4.685 4.685 0 0 1 1.621 1.86c.483-.196.99-.295 1.529-.295 1.195 0 2.217.446 3.065 1.341.849.895 1.27 1.974 1.27 3.24 0 .59-.088 1.124-.267 1.607.75.356 1.35.905 1.796 1.645.445.746.67 1.566.67 2.457Zm-12.51 3.614 4.954-7.42a.86.86 0 0 0 .122-.643.785.785 0 0 0-.361-.534.913.913 0 0 0-.642-.136.822.822 0 0 0-.563.347l-4.364 6.562-2.011-2.006a.797.797 0 0 0-.614-.253.948.948 0 0 0-.614.253.82.82 0 0 0-.24.605c0 .239.08.44.24.604l2.76 2.761.137.108c.16.108.323.16.483.16a.786.786 0 0 0 .712-.408Z" fill="#529A5F" /></g><defs><clipPath id="verified-badge_svg__a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
  );
}
