// @flow
/* eslint-disable */
// Generated from svgs/date.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Date = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M6 2v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2v2H8V2H6ZM5 9h14v11H5V9Z" fill="#000" /></svg>
  );
}
