// @flow
/* eslint-disable */
// Generated from svgs/Flag_of_France.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FlagOfFrance = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 900 600" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path fill="#ED2939" d="M0 0h900v600H0z" /><path fill="#fff" d="M0 0h600v600H0z" /><path fill="#002395" d="M0 0h300v600H0z" /></svg>
  );
}
