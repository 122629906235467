// @flow
/* eslint-disable */
// Generated from svgs/arrow_drop_down.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ArrowDropDown = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="m7 10 5 5 5-5z" /><path d="M0 0h24v24H0z" fill="none" /></svg>
  );
}
