// @flow
/* eslint-disable */
// Generated from svgs/bathroom.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Bathroom = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M11 2c-1.093 0-2 .907-2 2v5H2a1 1 0 1 0 0 2h.117l.453 3.62a4.977 4.977 0 0 0 1.914 3.318l-.455 1.82a.999.999 0 1 0 1.94.484l.351-1.41c.39.098.793.168 1.211.168h8.938c.418 0 .82-.07 1.21-.168l.352 1.41a.999.999 0 1 0 1.94-.484l-.455-1.819a4.977 4.977 0 0 0 1.914-3.32L21.885 11H22a1 1 0 1 0 0-2H11V4h3v1a1 1 0 0 0 0 2h.832a1 1 0 0 0 .326 0H16a1 1 0 0 0 0-2V4c0-1.093-.907-2-2-2h-3z" /></svg>
  );
}
