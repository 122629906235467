// @flow
/* eslint-disable */
// Generated from svgs/telescope.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Telescope = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 61 80" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M57.736 13.486c-.123.43-.42.786-.82.984L14.14 35.857a1.6 1.6 0 0 1-2.047-.543l-1.386-2.08a1.6 1.6 0 0 1 .444-2.218L50.968 4.471a1.592 1.592 0 0 1 2.258.507l4.343 7.238c.232.381.292.842.167 1.27Z" opacity=".2" /><path d="M60.315 10.57 55.972 3.33a4.798 4.798 0 0 0-6.78-1.524L9.376 28.352a4.8 4.8 0 0 0-1.332 6.656l1.387 2.08a4.798 4.798 0 0 0 6.14 1.632L25.8 33.605V39.4a3.2 3.2 0 0 0-3.2 3.2v3.2a3.159 3.159 0 0 0 1.646 2.74L.605 75.139a1.6 1.6 0 1 0 2.39 2.125L25.8 51.608V79.4a1.6 1.6 0 1 0 3.2 0V51.608l22.805 25.655a1.6 1.6 0 0 0 2.39-2.125L30.555 48.54A3.159 3.159 0 0 0 32.2 45.8v-3.2a3.2 3.2 0 0 0-3.2-3.2v-7.395l29.344-14.673a4.8 4.8 0 0 0 1.97-6.762ZM29 45.8h-3.2v-3.2H29v3.2Zm28.736-32.314c-.123.43-.42.786-.82.984L14.14 35.857a1.6 1.6 0 0 1-2.047-.543l-1.386-2.08a1.6 1.6 0 0 1 .444-2.218L50.968 4.471a1.592 1.592 0 0 1 2.258.507l4.343 7.238c.232.381.292.842.167 1.27Z" /></svg>
  );
}
