// @flow
/* eslint-disable */
// Generated from svgs/add_alert.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const AddAlert = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M11.5 21c-.917 0-1.658-.763-1.658-1.706h3.316c0 .943-.741 1.706-1.658 1.706zm5.733-10.286v4.989L19 17.52v.909H4v-.909l1.767-1.817v-4.989c0-2.785 1.875-5.117 4.408-5.734v-.617c0-.754.592-1.363 1.325-1.363s1.325.609 1.325 1.363v.617c2.533.617 4.408 2.949 4.408 5.734zm-11.08-4.97c.347-.307.635-.423.847-.423V4c-.629 0-1.198.325-1.66.734-.47.415-.893.97-1.24 1.562a8.957 8.957 0 0 0-.832 1.878C3.077 8.796 2.965 9.44 3.01 10l1.256-.11c-.025-.32.038-.777.202-1.312a7.606 7.606 0 0 1 .705-1.589c.3-.51.64-.945.98-1.245zm10.695 0c-.348-.307-.636-.423-.848-.423V4c.629 0 1.198.325 1.66.734.47.415.892.97 1.24 1.562a8.9 8.9 0 0 1 .832 1.878c.191.622.303 1.265.258 1.826l-1.256-.11c.025-.32-.038-.777-.202-1.312a7.613 7.613 0 0 0-.705-1.589c-.3-.51-.64-.945-.98-1.245z" fillRule="evenodd" /></svg>
  );
}
