// @flow
/* eslint-disable */
// Generated from svgs/house-old.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const HouseOld = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 48 45" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path d="M40.164 7.68c.415 0 .78.263.91.657l4.263 12.783H34.56v-7.505c0-.542-.228-1.056-.628-1.421L28.965 7.68h11.2ZM6.925 8.337a.96.96 0 0 1 .91-.657h11.2l-4.967 4.514c-.4.365-.628.88-.628 1.42v7.506H2.663L6.925 8.337ZM21.12 32.64h5.76v9.6h-5.76z" opacity=".2" /><path d="M5.76 28.8H9.6v1.92H5.76zM38.4 28.8h3.84v1.92H38.4z" /><path d="M47.04 42.24h-.96v-19.2H48L42.897 7.73a2.878 2.878 0 0 0-2.733-1.97H38.4V.96a.96.96 0 0 0-1.92 0v4.8h-9.627l-1.56-1.42a1.921 1.921 0 0 0-2.585 0l-1.561 1.42H7.836c-1.24 0-2.341.793-2.733 1.97L0 23.04h1.92v19.2H.96a.96.96 0 0 0 0 1.92h46.08a.96.96 0 0 0 0-1.92ZM40.164 7.68c.415 0 .78.263.91.657l4.263 12.783H34.56v-7.505c0-.542-.228-1.056-.628-1.421L28.965 7.68h11.2Zm-33.239.657a.96.96 0 0 1 .91-.657h11.2l-4.967 4.514c-.4.365-.628.88-.628 1.42v7.506H2.663L6.925 8.337ZM3.84 42.24v-19.2h9.6v19.2h-9.6Zm17.28 0v-9.6h5.76v9.6h-5.76Zm7.68 0v-9.6c0-1.06-.86-1.92-1.92-1.92h-5.76c-1.06 0-1.92.86-1.92 1.92v9.6h-3.84V13.615l8.638-7.855H24l8.64 7.855V42.24H28.8Zm5.76 0v-19.2h9.6v19.2h-9.6Z" /><path d="M25.92 15.36h-3.84a.96.96 0 0 0 0 1.92h3.84a.96.96 0 0 0 0-1.92Z" /></svg>
  );
}
