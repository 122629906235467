// @flow
/* eslint-disable */
// Generated from svgs/building.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Building = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 60 60" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><g clipPath="url(#building_svg__a)"><path d="M40.8 24h-7.2v4.8h7.2V24z" fillOpacity=".2" /><path d="M54 57.6h-3.6v-54A3.604 3.604 0 0 0 46.8 0H13.2a3.604 3.604 0 0 0-3.6 3.6v54H6A1.2 1.2 0 1 0 6 60h48a1.2 1.2 0 1 0 0-2.4zm-30 0V40.8h12v16.8H24zm14.4 0V40.8a2.4 2.4 0 0 0-2.4-2.4H24a2.4 2.4 0 0 0-2.4 2.4v16.8H12v-54a1.202 1.202 0 0 1 1.2-1.2h33.6A1.202 1.202 0 0 1 48 3.6v54h-9.6z" /><path d="M40.8 21.6H19.2a2.4 2.4 0 0 0-2.4 2.4v4.8a2.4 2.4 0 0 0 2.4 2.4h21.6a2.4 2.4 0 0 0 2.4-2.4V24a2.4 2.4 0 0 0-2.4-2.4zm0 7.2H19.2V24h21.6v4.8zm0-21.6H19.2a2.4 2.4 0 0 0-2.4 2.4v4.8a2.4 2.4 0 0 0 2.4 2.4h21.6a2.4 2.4 0 0 0 2.4-2.4V9.6a2.4 2.4 0 0 0-2.4-2.4zm0 7.2H19.2V9.6h21.6v4.8z" /><path d="M40.8 9.6h-7.2v4.8h7.2V9.6zm2.4-7.2v50.4h-4.8v4.8H48V2.4h-4.8zM21.6 52.8H12v4.8h9.6v-4.8z" fillOpacity=".2" /></g><defs><clipPath id="building_svg__a"><path fill="#fff" d="M0 0h60v60H0z" /></clipPath></defs></svg>
  );
}
