// @flow
/* eslint-disable */
// Generated from svgs/apartment-old.svg

import * as React from "react";

type Props = {|
  size?: string | number,
  fill?: string,
  className?: string,
  css?: any
|};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ApartmentOld = ({ size, fill, className }: Props): React.Node => {
  return (
    <svg viewBox="0 0 48 48" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size} fill={fill ?? "currentColor"} className={className} css={style}><path opacity=".2" d="M11.52 32.64h9.6v13.44h-9.6zM36.48 47.04h-8.64v-28.8h2.88z" /><path d="M20.16 23.04h-7.68a.96.96 0 0 0 0 1.92h7.68a.96.96 0 0 0 0-1.92ZM20.16 15.36h-7.68a.96.96 0 0 0 0 1.92h7.68a.96.96 0 0 0 0-1.92ZM37.44 32.64H33.6a.96.96 0 0 0 0 1.92h3.84a.96.96 0 0 0 0-1.92ZM37.44 40.32H33.6a.96.96 0 0 0 0 1.92h3.84a.96.96 0 0 0 0-1.92ZM37.44 24.96H33.6a.96.96 0 0 0 0 1.92h3.84a.96.96 0 0 0 0-1.92ZM20.16 7.68h-7.68a.96.96 0 0 0 0 1.92h7.68a.96.96 0 0 0 0-1.92Z" /><path d="M47.04 46.08h-2.88V20.16c0-1.59-1.29-2.88-2.88-2.88H28.8V2.88A2.884 2.884 0 0 0 25.92 0H6.72a2.884 2.884 0 0 0-2.88 2.88v43.2H.96a.96.96 0 0 0 0 1.92h46.08a.96.96 0 0 0 0-1.92Zm-35.52 0V32.64h9.6v13.44h-9.6Zm11.52 0V32.64c0-1.06-.86-1.92-1.92-1.92h-9.6c-1.06 0-1.92.86-1.92 1.92v13.44H5.76V2.88c0-.528.432-.96.96-.96h19.2c.528 0 .96.432.96.96v43.2h-3.84Zm5.76 0V19.2h12.48c.528 0 .96.432.96.96v25.92H28.8Z" /></svg>
  );
}
